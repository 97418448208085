import type { FC } from 'react'
import ReactQuill, { ReactQuillProps } from 'react-quill'

import './EditorToolbar.scss'
import 'react-quill/dist/quill.snow.css'
import EditorToolbar, { modules, formats } from './EditorToolbar'

type TEditorProps = {
  description: string
  updateDescription: ReactQuillProps['onChange']
}

const Editor: FC<TEditorProps> = ({ description, updateDescription }) => (
  <div className='text-editor'>
    <EditorToolbar />
    <ReactQuill theme='snow' value={description} onChange={updateDescription} modules={modules} formats={formats} />
  </div>
)

export default Editor
