import { Path } from 'libraries/router/types'
import { BlogIcon, NewsIcon, HistoryIcon, AvatarPrimaryIcon } from 'assets'

import type { TSectionItem } from './types'

export const sidebarMenuItemsUtils: (isPermission: boolean) => TSectionItem[] = (isPermission: boolean) => [
  {
    title: 'General',
    menuItems: [
      {
        Icon: isPermission ? HistoryIcon : undefined,
        text: isPermission ? 'Dashboard' : undefined,
        navigateTo: isPermission ? Path.Dashboard : undefined,
      },
      {
        Icon: NewsIcon,
        text: 'News',
        navigateTo: Path.News,
      },
      {
        Icon: BlogIcon,
        text: 'Blog',
        navigateTo: Path.Blog,
      },
      {
        Icon: AvatarPrimaryIcon,
        text: 'My account',
        navigateTo: Path.MyAccount,
      },
    ],
  },
]
