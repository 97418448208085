import { styled } from '@mui/material/styles'
import { TableRow } from '@mui/material'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    color: '#9FA8B3',
    border: 'none',
    borderRadius: 'none',
    backgroundColor: '#060f1a',
  },

  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

export const StyledTableRow = styled(TableRow)(() => ({
  backgroundColor: '#060f1a',
  color: '#E6F4FF',

  '&:last-child td, &:last-child th': {
    border: 0,
  },

  '&:hover': {
    cursor: 'pointer',
    backgroundColor: '#081320',
  },

  td: {
    color: '#E6F4FF',
    border: 0,
    borderBottom: 'none',
    boxShadow: 'none',

    '&:last-child': {
      gap: '8px',
      display: 'flex',

      button: {
        width: '48px',
        height: '48px',
        padding: 0,
        border: 0,
        backgroundColor: '#001E32',
      },
    },

    img: {
      width: '100px',
      height: '60px',
    },
  },
}))
