import { lazy } from 'react'

import { Path } from './types'
import { detectAdminLevel } from 'utils'
import { getCookie } from 'libraries/cookie'

const SignIn = lazy(() => import('pages/Auth/SignIn'))

const ResetPassword = lazy(() => import('pages/Auth/ResetPassword'))
const ForgotPassword = lazy(() => import('pages/Auth/ForgotPassword'))

const Home = lazy(() => import('pages/Dashboard'))
const News = lazy(() => import('pages/News'))
const NewsCreate = lazy(() => import('pages/NewsCreate'))
const MyAccount = lazy(() => import('pages/MyAccount'))

const Error = lazy(() => import('pages/Error'))

const adminCookie = getCookie('adminLevel')
const admin = detectAdminLevel(String(adminCookie))

const routesList = [
  {
    element: SignIn,
    path: Path.SignIn,
    title: 'Amatuni Login',
    isPrivate: false,
  },
  {
    element: ForgotPassword,
    path: Path.ForgotPassword,
    title: 'Forgot Password',
    isPrivate: false,
  },
  {
    element: ResetPassword,
    path: Path.ResetPassword,
    title: 'Reset Password',
    isPrivate: false,
  },
  {
    element: Home,
    path: Path.Dashboard,
    isPrivate: true,
    isPermission: admin?.isAccess,
    title: 'Dashboard',
  },
  {
    element: News,
    path: Path.News,
    isPermission: admin?.isAccess,
    isPrivate: true,
    title: 'News',
  },
  {
    element: NewsCreate,
    path: Path.NewsCreate,
    isPrivate: true,
    isPermission: admin?.isAccess,
    title: 'Create news',
  },
  {
    element: NewsCreate,
    path: Path.NewsUpdate,
    isPrivate: true,
    isPermission: admin?.isAccess,
    title: 'Update news',
  },
  {
    element: News,
    path: Path.Blog,
    isPrivate: true,
    isPermission: admin?.isAccess,
    title: 'Blog',
  },
  {
    element: NewsCreate,
    path: Path.BlogCreate,
    isPrivate: true,
    isPermission: admin?.isAccess,
    title: 'Create blog',
  },
  {
    element: NewsCreate,
    path: Path.BlogUpdate,
    isPrivate: true,
    isPermission: admin?.isAccess,
    title: 'Update blog',
  },
  {
    element: MyAccount,
    path: Path.MyAccount,
    isPermission: admin?.isAccess,
    isPrivate: true,
    title: 'My Account',
  },
  {
    element: Error,
    path: Path.Error,
    isPrivate: false,
    title: 'Error',
  },
]

export default routesList
