import { newsImage } from 'assets'

const createData = (id: number, image: string, title: string, category: string, modified: string, created: string) => {
  return { id, image, title, category, modified, created }
}

export const newsDataMock = [
  createData(1, newsImage, '123', 'Bitcoin', '29/10/2023, 7:00 PM', '29/10/2023, 7:00 PM'),
  createData(2, newsImage, '4', 'Bitcoin', '29/10/2023, 7:00 PM', '29/10/2023, 7:00 PM'),
  createData(3, newsImage, '6', 'Bitcoin', '29/10/2023, 7:00 PM', '29/10/2023, 7:00 PM'),
  createData(4, newsImage, 'asdjskad', 'Bitcoin', '29/10/2023, 7:00 PM', '29/10/2023, 7:00 PM'),
  createData(5, newsImage, 'cam,snc', 'Bitcoin', '29/10/2023, 7:00 PM', '29/10/2023, 7:00 PM'),
  createData(
    6,
    newsImage,
    'Overview of the COSMOS ATOM project.',
    'Bitcoin',
    '29/10/2023, 7:00 PM',
    '29/10/2023, 7:00 PM'
  ),
  createData(
    7,
    newsImage,
    'Overview of the COSMOS ATOM project.',
    'Bitcoin',
    '29/10/2023, 7:00 PM',
    '29/10/2023, 7:00 PM'
  ),
  createData(
    8,
    newsImage,
    'Overview of the COSMOS ATOM project.',
    'Bitcoin',
    '29/10/2023, 7:00 PM',
    '29/10/2023, 7:00 PM'
  ),
  createData(
    9,
    newsImage,
    'Overview of the COSMOS ATOM project.',
    'Bitcoin',
    '29/10/2023, 7:00 PM',
    '29/10/2023, 7:00 PM'
  ),
  createData(
    10,
    newsImage,
    'Overview of the COSMOS ATOM project.',
    'Bitcoin',
    '29/10/2023, 7:00 PM',
    '29/10/2023, 7:00 PM'
  ),
  createData(
    11,
    newsImage,
    'Overview of the COSMOS ATOM project.',
    'Bitcoin',
    '29/10/2023, 7:00 PM',
    '29/10/2023, 7:00 PM'
  ),
  createData(
    12,
    newsImage,
    'Overview of the COSMOS ATOM project.',
    'Bitcoin',
    '29/10/2023, 7:00 PM',
    '29/10/2023, 7:00 PM'
  ),
  createData(
    13,
    newsImage,
    'Overview of the COSMOS ATOM project.',
    'Bitcoin',
    '29/10/2023, 7:00 PM',
    '29/10/2023, 7:00 PM'
  ),
  createData(
    14,
    newsImage,
    'Overview of the COSMOS ATOM project.',
    'Bitcoin',
    '29/10/2023, 7:00 PM',
    '29/10/2023, 7:00 PM'
  ),
  createData(
    15,
    newsImage,
    'Overview of the COSMOS ATOM project.',
    'Bitcoin',
    '29/10/2023, 7:00 PM',
    '29/10/2023, 7:00 PM'
  ),
]
