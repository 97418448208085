import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import axiosInstance from 'libraries/axios'
import { ELanguage } from 'types'

const useLanguageTranslation = () => {
  const { i18n } = useTranslation()

  const onLanguageChangeCallback = useCallback(
    (language: string) => {
      i18n.changeLanguage(language)
      Object.assign(axiosInstance.defaults, {
        headers: { ...axiosInstance.defaults.headers, 'x-custom-lang': language },
      })
    },
    [i18n]
  )

  return {
    language: i18n.language as ELanguage,
    isEnglish: i18n.language === 'en',
    onLanguageChangeCallback,
  }
}
export default useLanguageTranslation
