import type { FC } from 'react'
import classNames from 'classnames'

import { ESizes } from 'types'
import { Text } from 'components'
import { ArrowRightIcon } from 'assets'

import type { ISelectHead } from './types'
import styles from './Select.module.scss'

const SelectHead: FC<ISelectHead> = ({
  isOpen,
  onClick,
  LText = '',
  className = '',
  size = ESizes.Large,
  selectedItem = '',
}) => {
  const isLarge = size === ESizes.Large
  const isSmall = size === ESizes.Small

  return (
    <div
      role='button'
      onClick={onClick}
      className={classNames(styles.container, {
        [className]: className,
        [styles.container_open]: isOpen,
        [styles.container_large]: isLarge,
        [styles.container_small]: isSmall,
      })}
    >
      <p
        className={classNames(styles.container__text, {
          [styles.container__text_large]: isLarge,
          [styles.container__text_small]: isSmall,
        })}
      >
        <Text text={LText} tagName='span' className={styles.container__text__placeholder} />

        <Text tagName='span' text={selectedItem} />
      </p>

      <ArrowRightIcon
        className={classNames(styles.container__arrow, {
          [styles.container__arrow_transform]: isOpen,
        })}
      />
    </div>
  )
}

export default SelectHead
