import { FC } from 'react'

import { Link, NavLink } from 'react-router-dom'

import { ModeSwitch, LangDropdown } from 'components'

import { Path } from 'libraries/router/types'
import { AuthSelectors } from 'store/auth/selector'
import { useAppSelector } from 'libraries/redux'
import { AmatuniPrimaryIcon, userImage } from 'assets'

import styles from './Header.module.scss'

export type THeaderProps = {
  isPermission: boolean
}

const Header: FC<THeaderProps> = ({ isPermission }) => {
  const { data: user, loading } = useAppSelector(AuthSelectors.getProfile)

  if (loading) {
    return null
  }

  return (
    <header className={styles.wrapper}>
      <div className={styles.wrapper__left}>
        <ModeSwitch />

        <LangDropdown />
      </div>

      <NavLink to={isPermission ? Path.Dashboard : Path.News} className={styles.wrapper__center}>
        <AmatuniPrimaryIcon />
        <h1 className={styles.wrapper__center__text}>AMATUNI</h1>
      </NavLink>

      <Link to={Path.MyAccount}>
        <div className={styles.wrapper__right}>
          <div>
            <img src={userImage} alt='' />
          </div>
          <p>{user?.role === 'superAdmin' ? 'Super Admin' : 'Admin'}</p>
        </div>
      </Link>
    </header>
  )
}

export default Header
