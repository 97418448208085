import { Fragment, ReactNode } from 'react'

import Header from '../Header'
import Sidebar from '../Sidebar'

import styles from './DashboardLayout.module.scss'

type Props = {
  children: ReactNode
  isPrivate: boolean
  isPermission?: boolean
}

const DashboardLayout = ({ children, isPrivate, isPermission }: Props) => (
  <Fragment>
    {!isPrivate ? (
      children
    ) : (
      <main className={styles.wrapper}>
        <Header isPermission={Boolean(isPermission)} />
        <section className={styles.wrapper__content}>
          <Sidebar isPermission={Boolean(isPermission)} />
          {children}
        </section>
      </main>
    )}
  </Fragment>
)

export default DashboardLayout
