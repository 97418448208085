import { object, string, boolean } from 'yup'

const paymentCreditScheme = object().shape({
  creditCard: string()
    .required('Card Number is required')
    .matches(/^.{19}$/, 'Card number must be exactly 16 digits'),
  fullName: string()
    .required('Field is required')
    .matches(/.*\s.*/g, 'Must contain at least one whitespace'),
  date: string()
    .required('Date is required')
    .matches(/^(0[1-9]|1[0-2])\/?(([0-9]{4}|[0-9]{2})$)/, 'Card date must be in MM/YY format'),
  cvv: string()
    .required('CVV is required')
    .matches(/^\d{3,4}$/, 'CVV length should be 3 or 4 length'),
  termsAndPolicy: boolean().required().oneOf([true], 'The terms and conditions must be accepted.'),
})

export default paymentCreditScheme
