export const adminRoles = {
  admin: 'admin',
  superAdmin: 'superAdmin',
}

export const detectAdminLevel = (admin: string) => {
  switch (admin) {
    case adminRoles.admin: {
      return {
        isAccess: false,
        permission: adminRoles.admin,
      }
    }

    case adminRoles.superAdmin: {
      return {
        isAccess: true,
        permission: adminRoles.superAdmin,
      }
    }

    default: {
      return {
        isAccess: undefined,
        permission: undefined,
      }
    }
  }
}
