import { createSlice } from '@reduxjs/toolkit'

import { gatCoins } from './actions'

export const initialState: any = {
  coins: {
    data: [],
    error: null,
    loading: false,
  },
}

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(gatCoins.pending, state => {
      state.coins.loading = true
      state.coins.error = null
    })

    builder.addCase(gatCoins.fulfilled, (state, { payload }) => {
      state.coins.loading = false
      state.coins.error = null
      state.coins.data = payload
    })

    builder.addCase(gatCoins.rejected, (state, action) => {
      state.coins.loading = false
      state.coins.error = action.payload as null
    })
  },
})

const dashboardReducer = dashboardSlice.reducer

export default dashboardReducer
