import type { FC } from 'react'
import classNames from 'classnames'

import type { TSidebarMenuProps } from '../types'

import SidebarMenuSection from './SidebarMenuSection'
import styles from './SidebarMenu.module.scss'

const SidebarMenu: FC<TSidebarMenuProps> = ({ sidebarMenuItems, className = '', setOpen, open }) => {
  const allSectionsWrapperClassName = classNames(styles.wrapper, {
    [styles.wrapper__closed]: !open,
    [className]: className,
  })

  const renderItems = sidebarMenuItems?.map(({ menuItems, title }, idx) => (
    <SidebarMenuSection setOpen={setOpen} menuItems={menuItems} title={title} open={open} key={idx} />
  ))

  return <div className={allSectionsWrapperClassName}>{renderItems}</div>
}

export default SidebarMenu
