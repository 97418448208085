import { useRef } from 'react'
import classNames from 'classnames'

import { EButtonVariants, ESizes } from 'types'
import { DeleteIcon, EditIcon, UploadPhotoIcon } from 'assets'

import styles from './UploadPhoto.module.scss'

import Button from '../Button'

interface FileEventTarget extends EventTarget {
  files: FileList
}

interface FileEvent extends React.ChangeEvent<HTMLInputElement> {
  target: FileEventTarget & HTMLInputElement
}

const UploadPhoto = ({ image, handleImageUpload }: any) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    const file = e.dataTransfer.files[0]

    if (file) {
      handleImageUpload(file)
    }
  }

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
  }

  const handleClick = () => {
    inputRef.current?.click()
  }

  const handleFileChange = (e: FileEvent) => {
    const file = e.target.files[0]
    if (file) {
      handleImageUpload(file)
    }
  }

  return (
    <div
      className={classNames(styles.wrapper, { [styles.wrapper__active]: image })}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onClick={handleClick}
    >
      {image ? (
        <>
          <div>
            <Button size={ESizes.Small} variant={EButtonVariants.Primary} className={styles.wrapper__inner__button}>
              <DeleteIcon />
            </Button>
            <Button size={ESizes.Small} variant={EButtonVariants.Primary} className={styles.wrapper__inner__button}>
              <EditIcon />
            </Button>
          </div>
          <img src={image as string} alt='Uploaded' />
        </>
      ) : (
        <>
          <UploadPhotoIcon />
          <p>Drag photo here or click to browse it.</p>
        </>
      )}
      <input type='file' ref={inputRef} onChange={handleFileChange} accept='image/*' />
    </div>
  )
}

export default UploadPhoto
