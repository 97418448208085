import type { FC } from 'react'
import { noop } from 'lodash'
import classNames from 'classnames'

import { Button } from 'components'
import { ESizes, EButtonVariants } from 'types'

import type { ISelectItemProps } from './types'
import styles from './Select.module.scss'

const SelectItem: FC<ISelectItemProps> = ({
  id,
  text,
  onClick = noop,
  className = '',
  deActive = false,
  size = ESizes.Small,
}) => (
  <Button
    size={size}
    onClick={() => onClick(id)}
    variant={EButtonVariants.Tertiary}
    className={classNames(styles.select_item, {
      [className]: className,
      [styles.select_item_de_active]: deActive,
    })}
  >
    {text}
  </Button>
)

export default SelectItem
