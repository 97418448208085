import { object, string } from 'yup'

const inviteuserScheme = object().shape({
  email: string()
    .email('Invalid value')
    .required('Email is required')
    .matches(/^[^\s]+$/, 'Must not contain whitespace'),
})

export default inviteuserScheme
