import { useNavigate } from 'react-router-dom'

import { useAppDispatch } from 'libraries/redux'
import { clearProfileData } from 'store/auth/actions'
import { getCookie, removeCookie } from 'libraries/cookie'
import { isEmpty } from 'lodash'

const useAuthentication = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const userCookie = getCookie('user')
  const tokenCookie = getCookie('token')
  const isAuthenticated = Boolean(!!tokenCookie && !isEmpty(userCookie))

  const handleLogout = () => {
    navigate('/')
    removeCookie('user')
    removeCookie('token')
    removeCookie('adminLevel')
    removeCookie('refreshToken')
    dispatch(clearProfileData())

    // location.reload()
  }

  return { isAuthenticated, handleLogout, user: null }
}

export default useAuthentication
