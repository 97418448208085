import { object, string, boolean } from 'yup'

const registrationScheme = object().shape({
  firstName: string()
    .required('First Name is required')
    .matches(/^[^\s]+$/, 'Must not contain whitespace')
    .min(2, 'Must be minimum 2 characters long.'),
  lastName: string()
    .required('Last Name is required')
    .matches(/^[^\s]+$/, 'Must not contain whitespace')
    .min(2, 'Must be minimum 2 characters long.'),
  nickname: string()
    .required('Nick name is required')
    .matches(/^[^\s]+$/, 'Must not contain whitespace')
    .min(4, 'Must be minimum 4 characters long.'),
  email: string()
    .email('Invalid value')
    .required('Email is required')
    .matches(/^[^\s]+$/, 'Must not contain whitespace'),
  password: string()
    .required('Password is required')
    .matches(/^[^\s]+$/, 'Must not contain whitespace')
    .min(8, 'Must be minimum 8 characters long.')
    .max(20, 'Must be maximum 20 characters long.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/,
      'Password should contain at least 1 uppercase letter, 1 lowercase letter and 1 number'
    ),
  passwordConfirm: string()
    .required('Password Confirmation is required')
    .test('passwords-match', 'Passwords must match', function (value) {
      return this.parent.password === value
    }),
  termsAndPolicy: boolean().required().oneOf([true], 'The terms and conditions must be accepted.'),
})

export default registrationScheme
