import { FC, useState, useRef } from 'react'
import classNames from 'classnames'

import SidebarMenu from './SidebarMenu'
import SidebarFooter from './SidebarFooter'
import SidebarSwitch from './SidebarSwitch'
import useOutsideClick from 'hooks/useOnClickOutside'

import { sidebarMenuItemsUtils } from './utils'
import styles from './Sidebar.module.scss'

export type TSidebarProps = {
  isPermission: boolean
}

const Sidebar: FC<TSidebarProps> = ({ isPermission }) => {
  const ref = useRef(null)
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false)

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen)
  }

  const handleSidebarClose = () => {
    setIsSidebarOpen(false)
  }

  useOutsideClick(ref, handleSidebarClose)

  const sidebarWrapperClassName = classNames(styles.wrapper, {
    [styles.wrapper__closed]: !isSidebarOpen,
  })

  const formatMenuData = sidebarMenuItemsUtils(isPermission)?.map(element => {
    return {
      ...element,
      menuItems: element?.menuItems?.filter(item => !Object.values(item).every(value => value === undefined)),
    }
  })

  return (
    <nav ref={ref} className={sidebarWrapperClassName}>
      <SidebarMenu setOpen={toggleSidebar} open={isSidebarOpen} sidebarMenuItems={formatMenuData} />

      <SidebarFooter open={isSidebarOpen} />

      <SidebarSwitch open={isSidebarOpen} setOpen={toggleSidebar} />
    </nav>
  )
}

export default Sidebar
