import { FC } from 'react'
import TableBody from '@mui/material/TableBody'
import { Paper, Table, TableContainer, TableHead, TableRow } from '@mui/material'

import { NewsRequestState } from 'store/news/types'
import { StyledTableCell } from './NewsTable.styles'

import TableItem from './TableItem'

interface INewsTableProps {
  deleteNews: (id: number) => void
  data: NewsRequestState[]
  isNewsPage: boolean
}

const NewsTable: FC<INewsTableProps> = ({ deleteNews, data, isNewsPage }) => {
  return (
    <TableContainer sx={{ maxHeight: 550 }} component={Paper}>
      <Table sx={{ minWidth: 700 }} stickyHeader aria-label='sticky table'>
        <TableHead>
          <TableRow>
            <StyledTableCell>id</StyledTableCell>
            <StyledTableCell>Thumbnail</StyledTableCell>
            <StyledTableCell>Title</StyledTableCell>
            <StyledTableCell>Category</StyledTableCell>
            <StyledTableCell align='center'>Created at</StyledTableCell>
            <StyledTableCell align='right'>Actions</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map(item => (
            <TableItem key={item?.id} data={item} deleteNews={deleteNews} isNewsPage={isNewsPage} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default NewsTable
