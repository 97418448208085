import React from 'react'
import { SelectChangeEvent } from '@mui/material'

import styles from './CoinsSelect.module.scss'
import { CoinsSelectStyled, MenuItemStyled } from './CoinsSelect.styles'

import { renderIconByCoinName } from 'utils'

const CoinsSelect = ({ activeCoin, coinsList, onChange }: any) => {
  const renderCoinsList = coinsList?.map(
    (coin: { id: number; coinId: string; image: string | undefined; name: string }) => {
      const CoinIcon = renderIconByCoinName(coin.coinId)

      return (
        <MenuItemStyled key={coin.coinId} value={coin.id}>
          <CoinIcon />
          {coin.name}
        </MenuItemStyled>
      )
    }
  )

  return (
    <div className={styles.wrapper}>
      <CoinsSelectStyled
        select
        label='Category'
        variant='outlined'
        value={activeCoin}
        onChange={(event: React.ChangeEvent<{ value: unknown }>) => onChange(event as SelectChangeEvent)}
        InputLabelProps={{ shrink: false }}
      >
        {renderCoinsList}
      </CoinsSelectStyled>
    </div>
  )
}

export default CoinsSelect
