export enum Path {
  Error = '*',
  SignIn = '/',
  News = '/news',
  Blog = '/blog',
  MyAccount = '/my-account',
  NewsCreate = '/news/create',
  NewsUpdate = '/news/update/:id',
  BlogCreate = '/blog/create',
  BlogUpdate = '/blog/update/:id',
  ResetPassword = '/resetPassword',
  ForgotPassword = '/forgotPassword',
  Dashboard = '/dashboard',
}
