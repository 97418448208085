import { FacebookIcon, InstagramIcon, TelegramIcon, YoutubeIcon } from 'assets'

import { ISocialLinks } from 'types'

export const courseSocialLinks: ISocialLinks[] = [
  {
    navigate: '/components',
    Icon: FacebookIcon,
  },
  {
    navigate: '/components',
    Icon: InstagramIcon,
  },
  {
    navigate: '/components',
    Icon: TelegramIcon,
  },
  {
    navigate: '/components',
    Icon: YoutubeIcon,
  },
]
