import { FC, Suspense, useMemo, useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'

import { useAuthentication } from 'hooks'
import routesList from 'libraries/router/routes'
import { useAppDispatch } from 'libraries/redux'
import { gatCoins } from 'store/dashboard/actions'
import { getProfileData } from 'store/auth/actions'
import { AuthLayout, HelmetLayout, RouteLoader, RoutesWrapper } from 'components'

import DashboardLayout from '../DashboardLayout'
import { Path } from 'libraries/router/types'

const PageLayout: FC = () => {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const { isAuthenticated } = useAuthentication()

  useEffect(() => {
    if (location.pathname !== Path.SignIn) {
      dispatch(getProfileData())
      dispatch(gatCoins())
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderRoutes = useMemo(
    () =>
      routesList.map(({ element: Element, path, title, isPrivate, isPermission }) => (
        <Route
          key={path}
          path={path}
          element={
            <RoutesWrapper isAuthenticated={isAuthenticated} isPrivate={isPrivate} isPermission={isPermission}>
              <HelmetLayout key={title} title={title}>
                <AuthLayout isPrivate={isPrivate}>
                  <DashboardLayout isPrivate={isPrivate} isPermission={isPermission}>
                    <Suspense fallback={<RouteLoader />}>
                      <Element />
                    </Suspense>
                  </DashboardLayout>
                </AuthLayout>
              </HelmetLayout>
            </RoutesWrapper>
          }
        />
      )),
    [isAuthenticated]
  )

  return <Routes>{renderRoutes}</Routes>
}

export default PageLayout
