import { forwardRef, useState, type FocusEvent, type ChangeEvent, type LegacyRef, type KeyboardEvent } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { EInput, ESizes } from 'types'
import { Text, Tooltip } from 'components'
import { ErrorIcon, HideIcon, ShowIcon } from 'assets'

import type { TInput } from './types'
import styles from './Input.module.scss'

const Input = forwardRef(
  (
    {
      name,
      Icon,
      error,
      label,
      value,
      onBlur,
      onFocus,
      register,
      disabled,
      onChange,
      maxLength,
      minLength,
      onKeyDown,
      className = '',
      placeholder = '',
      tooltipPlacement,
      containerClass = '',
      autoComplete = 'off',
      size = ESizes.Small,
      type = EInput.TextType,
    }: TInput,
    ref: LegacyRef<HTMLInputElement>
  ) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)

    const { t } = useTranslation()
    const isLarge = size === ESizes.Large
    const isPassword = type === EInput.PasswordType

    const inputClassName = classNames(className, styles.wrapper__input, {
      [styles.wrapper__input__error]: error,
      [styles.wrapper__input__icon]: Icon,
      [styles.wrapper__input__large]: isLarge,
      [styles.wrapper__input__large__icon]: isLarge && Icon,
    })

    const iconClassNames = classNames(styles.wrapper__icon, {
      [styles.wrapper__icon__large]: isLarge,
    })

    const eyeIconToggler = () => {
      setIsOpen(!isOpen)
    }

    const onChangeValueHandler = (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      onChange?.(value, e)
    }

    const onBlurHandler = (e: FocusEvent<HTMLInputElement>) => {
      onBlur?.(e)
    }

    const onFocusHandler = (e: FocusEvent<HTMLInputElement>) => {
      onFocus?.(e)
    }

    const onKeyDownHandler = (e: KeyboardEvent<HTMLInputElement>) => {
      onKeyDown?.(e)
    }

    const onRegisterChangeCallback = {
      onChange: onChangeValueHandler,
      onBlur: onBlurHandler,
    }

    return (
      <div
        data-testid='input-wrapper'
        className={classNames(styles.wrapper, {
          [containerClass]: containerClass,
        })}
      >
        {label && <Text data-testid='input-label' text={label} className={styles.wrapper__label} />}

        <div className={styles.wrapper__box}>
          {Icon ? <Icon data-testid='input-icon' className={iconClassNames} /> : null}

          <input
            data-testid='input'
            ref={ref}
            name={name}
            value={value}
            disabled={disabled}
            maxLength={maxLength}
            minLength={minLength}
            onFocus={onFocusHandler}
            className={inputClassName}
            autoComplete={autoComplete}
            onKeyDown={onKeyDownHandler}
            onChange={onChangeValueHandler}
            placeholder={t(String(placeholder))}
            onBlur={onBlurHandler}
            type={isOpen ? EInput.TextType : type}
            {...(register ? register(name, onRegisterChangeCallback) : null)}
          />

          {error || isPassword ? (
            <div className={styles.wrapper__box__container}>
              {error ? (
                <Tooltip
                  isError
                  title={error}
                  isShowFromStart
                  Icon={ErrorIcon}
                  placement={tooltipPlacement}
                  dataTestId='input-error-icon'
                  titleClassName={styles.wrapper__box_right__tooltip_title}
                />
              ) : null}

              {isPassword ? (
                <div
                  data-testid='input-password'
                  onClick={eyeIconToggler}
                  className={classNames(styles.wrapper__box__eye, { [styles.wrapper__box__eye__open]: isOpen })}
                >
                  {!isOpen ? <HideIcon /> : <ShowIcon />}
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    )
  }
)

export default Input
