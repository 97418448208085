import { createAsyncThunk } from '@reduxjs/toolkit'

import axiosInstance from 'libraries/axios'
import { showNotification, ToastVersions } from 'libraries/toastify'

export const gatCoins = createAsyncThunk('coins/get', async (_, { rejectWithValue }) => {
  try {
    const { data } = await axiosInstance.get('portfolio/coins')

    return data
  } catch (error: any) {
    showNotification(ToastVersions.error, error.response.data.message)

    return rejectWithValue(error.response.data)
  }
})
