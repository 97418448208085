import { Quill } from 'react-quill'

import './EditorToolbar.scss'

// const undoChange = () => {
//   this.quill.history.undo() as unknown as any
// }

// const redoChange = () => {
//   this.quill.history.redo()
// }

const BlockEmbed = Quill.import('blots/block/embed')
class DividerBlot extends BlockEmbed {}
DividerBlot.blotName = 'divider'
DividerBlot.tagName = 'hr'
Quill.register(DividerBlot)

// const addDivider = () => {
//   const range = this.quill.getSelection(true)
//   this.quill.insertText(range.index, '\n', Quill.sources.USER)
//   this.quill.insertEmbed(range.index + 1, 'divider', true, Quill.sources.USER)
//   this.quill.setSelection(range.index + 2, Quill.sources.SILENT)
// }

const Inline = Quill.import('blots/inline')
class EmphBlot extends Inline {}
EmphBlot.blotName = 'em'
EmphBlot.tagName = 'em'
EmphBlot.className = 'custom-em'
Quill.register('formats/em', EmphBlot)

// const addBorder = () => {
//   const range = this.quill.getSelection()
//   if (range) {
//     this.quill.format('em', true)
//   }
// }

// const insertStar = () => {
//   const cursorPosition = this.quill.getSelection().index
//   this.quill.insertText(cursorPosition, '★')
//   this.quill.setSelection(cursorPosition + 1)
// }

const colorsList = [
  '#000000',
  '#e60000',
  '#ff9900',
  '#ffff00',
  '#008a00',
  '#0066cc',
  '#9933ff',
  '#ffffff',
  '#facccc',
  '#ffebcc',
  '#ffffcc',
  '#cce8cc',
  '#cce0f5',
  '#ebd6ff',
  '#bbbbbb',
  '#f06666',
  '#ffc266',
  '#ffff66',
  '#66b966',
  '#66a3e0',
  '#c285ff',
  '#888888',
  '#a10000',
  '#b26b00',
  '#b2b200',
  '#006100',
  '#0047b2',
  '#6b24b2',
  '#444444',
  '#5c0000',
  '#663d00',
  '#666600',
  '#003700',
  '#002966',
  '#3d1466',
  '#361999',
  '#ffebe9',
  '#1b1839',
]

export const modules = {
  toolbar: {
    container: '#toolbar',
    handlers: {
      //   undo: undoChange,
      //   redo: redoChange,
      //   divide: addDivider,
      //   border: addBorder,
      //   star: insertStar,
    },
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
}

// Formats objects for setting up the Quill editor
export const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'align',
  'strike',
  'script',
  'blockquote',
  'background',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'color',
  'em',
  'p',
  'divider',
  'hr',
  'formats/em',
]

const Dropdown = ({ list, className }: { list: any[]; className: string }) => {
  return (
    <select className={className}>
      {Array.isArray(list) && list.map((item, index) => <option key={index} value={item}></option>)}
    </select>
  )
}

const EditorToolbar = () => (
  <div id='toolbar'>
    <span className='ql-formats'>
      <button className='ql-bold' />
      <button className='ql-italic' />
      <button className='ql-underline' />
      <button className='ql-strike' />

      <select className='ql-header' defaultValue='6'>
        <option value='1'>Heading 1</option>
        <option value='2'>Heading 2</option>
        <option value='3'>Heading 3</option>
        <option value='4'>Heading 4</option>
        <option value='5'>Heading 5</option>
        <option value='6'>Heading 6</option>
        <option value=''>Normal</option>
      </select>

      <button className='ql-link' />

      <button className='ql-align' />
      <button className='ql-align' value='center' />
      <button className='ql-align' value='right' />
      <button className='ql-align' value='justify' />

      <Dropdown list={colorsList} className='ql-color' />

      <button className='ql-list' value='ordered' />
      <button className='ql-list' value='bullet' />
    </span>
  </div>
)

export default EditorToolbar
