import { type FC, useRef, useState } from 'react'
import classNames from 'classnames'

import { useOnClickOutside } from 'hooks'

import styles from './Select.module.scss'
import SelectHead from './SelectHead'
import SelectItem from './SelectItem'
import type { ISelectProps, TSelectData } from './types'

const Select: FC<ISelectProps> = ({
  size,
  LText,
  onChange,
  selectData,
  itemClass = '',
  className = '',
  defaultText = '',
  contentClass = '',
  containerClass = '',
}) => {
  const ref = useRef<HTMLDivElement>(null)

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [selectedItem, setSelectedItem] = useState<number | null>(selectData[0].id)

  const toggleSelectHandler = () => {
    setIsOpen(!isOpen)
  }

  const renderSelectList = selectData.map(({ id, text, onClick, value }: TSelectData) => {
    const activeItem = selectData.find((element: TSelectData) => element.id === selectedItem)

    const selectHandler = () => {
      onClick?.(id)
      setSelectedItem(id)

      if (onChange) {
        onChange(activeItem?.value)
      }

      setIsOpen(false)
    }

    return (
      <SelectItem
        id={id}
        key={id}
        size={size}
        text={text}
        deActive={!value}
        className={classNames({
          [itemClass]: itemClass,
          [styles.wrapper__select__active]: id === selectedItem || (text === defaultText && !selectedItem),
          [styles.wrapper__select__de_active]: !value,
        })}
        onClick={() => selectHandler()}
      />
    )
  })

  const result = selectData.find((element: TSelectData) => element.id === selectedItem)

  useOnClickOutside(ref, () => setIsOpen(false))

  return (
    <div
      ref={ref}
      className={classNames(styles.wrapper, {
        [containerClass]: containerClass,
      })}
    >
      <SelectHead
        size={size}
        LText={LText}
        isOpen={isOpen}
        className={className}
        onClick={toggleSelectHandler}
        selectedItem={result?.text || defaultText}
      />

      {isOpen && (
        <div
          className={classNames(styles.wrapper__select, {
            [contentClass]: contentClass,
            [styles.wrapper__select__large]: size === 'large',
          })}
        >
          {renderSelectList}
        </div>
      )}
    </div>
  )
}

export default Select
