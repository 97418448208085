import { RootState } from 'types'

const getSignIn = (state: RootState) => state.auth.signIn
const getProfile = (state: RootState) => state.auth.profile
const getCurrentAuth = (state: RootState) => state.auth
const getResetPassword = (state: RootState) => state.auth.resetPassword
const getForgotPassword = (state: RootState) => state.auth.forgotPassword

export const AuthSelectors = {
  getSignIn,
  getProfile,
  getCurrentAuth,
  getResetPassword,
  getForgotPassword,
}
