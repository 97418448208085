import classNames from 'classnames'

import { Text } from 'components'
import { AmatuniLogoWithTextIcon, imgPrograms } from 'assets'

import styles from './LeftBanner.module.scss'

const LeftBanner = () => {
  const renderBackground = new Array(4)
    .fill('')
    .map((_, index) => (
      <div key={index} className={classNames(styles.wrapper__background, styles[`wrapper__background_${index}`])} />
    ))

  return (
    <>
      <div className={styles.wrapper__background__container}>{renderBackground}</div>

      <div className={styles.wrapper}>
        <div className={styles.wrapper__heading}>
          <Text text='ADMIN PANEL' className={styles.wrapper__heading__title} />

          <div className={styles.wrapper__heading__divider} />

          <div className={classNames(styles.wrapper__column, styles.wrapper__heading__amatuni)}>
            <AmatuniLogoWithTextIcon />
          </div>
        </div>

        <div className={styles.wrapper__programs__container}>
          <img className={styles.wrapper__programs} src={imgPrograms} alt='programs' width='100%' height='100%' />
        </div>
      </div>
    </>
  )
}

export default LeftBanner
