import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { format, parseISO } from 'date-fns'

import { Button, Modal } from 'components'
import { NewsRequestState } from 'store/news/types'
import { DeleteIcon, EditIcon } from 'assets'
import { EButtonVariants, ESizes } from 'types'
import { renderIconByCoinName, renderCoinName } from 'utils'

import styles from './NewsTable.module.scss'
import { StyledTableCell, StyledTableRow } from './NewsTable.styles'

interface INewsTableItemProps {
  deleteNews: (id: number) => void
  data: NewsRequestState
  isNewsPage: boolean
}

const TableItem: FC<INewsTableItemProps> = ({ data, deleteNews, isNewsPage }) => {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)

  const { id, coin, image, name, createdAt } = data
  const CoinIcon = renderIconByCoinName(coin.name)
  const formattedDate = createdAt && format(parseISO(createdAt), 'MM/dd/yyyy HH:mm')

  const generateId = (id: number) => {
    if (id < 10) return `#00${id}`
    if (id < 100) return `#0${id}`

    return id
  }

  return (
    <>
      <StyledTableRow
        key={id}
        onClick={() => {
          if (isNewsPage) {
            navigate(`/news/update/${id}`)
          } else {
            navigate(`/blog/update/${id}`)
          }
        }}
      >
        <StyledTableCell>{generateId(id)}</StyledTableCell>
        <StyledTableCell>
          <img src={image} alt='news' />
        </StyledTableCell>
        <StyledTableCell>{name}</StyledTableCell>
        <StyledTableCell>
          <div className={styles.coin}>
            <CoinIcon />
            <p>{renderCoinName(coin.name)}</p>
          </div>
        </StyledTableCell>
        <StyledTableCell align='center'>{formattedDate}</StyledTableCell>
        <StyledTableCell align='right'>
          <Button
            size={ESizes.Small}
            variant={EButtonVariants.Primary}
            className={styles.inner__button}
            onClick={e => {
              e.stopPropagation()
              setOpen(true)
            }}
          >
            <DeleteIcon />
          </Button>
          <Button size={ESizes.Small} variant={EButtonVariants.Primary}>
            <EditIcon />
          </Button>
        </StyledTableCell>
      </StyledTableRow>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        title={`Delete ${isNewsPage ? 'News' : 'Blog'}`}
        className={styles.modal}
      >
        <p className={styles.modal__description}>
          Are you sure you want to delete this {isNewsPage ? 'news' : 'blog'} ? This action cannot be undone.
        </p>
        <Button
          size={ESizes.Small}
          variant={EButtonVariants.Primary}
          className={styles.inner__button}
          onClick={e => {
            e.stopPropagation()
            deleteNews(id)
            setOpen(false)
          }}
        >
          Delete {isNewsPage ? 'News' : 'Blog'}
        </Button>
        <Button size={ESizes.Small} variant={EButtonVariants.Primary} onClick={() => setOpen(false)}>
          Cancel
        </Button>
      </Modal>
    </>
  )
}

export default TableItem
