import { Fragment, ReactNode } from 'react'
import classNames from 'classnames'

import LeftBanner from './LeftBanner'
import styles from './AuthLayout.module.scss'

type Props = {
  children: ReactNode
  isPrivate: boolean
}

const AuthLayout = ({ children, isPrivate }: Props) => (
  <Fragment>
    {isPrivate ? (
      children
    ) : (
      <div className={styles.wrapper}>
        <LeftBanner />

        <div className={classNames(styles.wrapper__right)}>{children}</div>
      </div>
    )}
  </Fragment>
)

export default AuthLayout
