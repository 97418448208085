import { FC, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useAuthentication } from 'hooks'

import { Path } from 'libraries/router/types'

import { TRoutesWrapper } from './types'

const RoutesWrapper: FC<TRoutesWrapper> = ({ children, isPrivate, isPermission }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const { isAuthenticated } = useAuthentication()

  useEffect(() => {
    if (isPrivate && !isAuthenticated) {
      return navigate(Path.SignIn)
    } else if (location.pathname === Path.SignIn && isAuthenticated) {
      return navigate(isPermission ? Path.Dashboard : Path.News)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return children
}

export default RoutesWrapper
